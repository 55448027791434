<template>
  <article id="home">
    <section id="top">
      <p>画家であり占星術研究家であり<br class="pc-none">プログラマーである<br>文月ルビー（文月紅玉）<br class="pc-none">のプロフィールサイト</p>
    </section>

    <div v-swiper:mySwiper="swiperOption">
      <div class="swiper-wrapper">
        <div class="swiper-slide" :key="banner" v-for="banner in banners">
          <img :src="banner">
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>

  </article>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      banners: [ '/img/universe/chain_2016.jpg', '../assets/logo.png', '/3.jpg' ],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        // ...
      }
    }
  },
  mounted() {
    console.log('Current Swiper instance object', this.mySwiper)
    this.mySwiper.slideTo(3, 1000, false)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#top{
  line-height: 2.4em;
}
</style>