<template>
  <div class="about">
    <h1>This is an about page</h1>
    <Home></Home>

  </div>

</template>

<script>
import Home from '../components/Home'

export default {

  components:{
    Home
  }
}
</script>
